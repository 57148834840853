import React, { FC } from 'react';
import 'chart.js/auto';

import s from './chart.module.sass';
import Doughnut from './Doughnut/Doughnut';
import languageSetter from '../../functions/i18n';
import Information from './Information/Information';

interface LocalChart {
    title: string
}

const Chart: FC = () => {
    const local: LocalChart = languageSetter.t('TOKENOMICS.translations');

    return (
        <div className={s.chart} id='tokenomics'>
            <div className={s.chart__title}>{local.title}</div>
            <div className={s.chart__main}>
                <Doughnut/>
                <Information/>
            </div>
        </div>
    );
};

export default Chart;
