import { scroller } from 'react-scroll';
import {
    NavbarLink,
    scrollDelay,
    scrollDuration,
    scrollOffset,
    smoothScroll
} from '../shared-ui/constants/navbar/navbar';

export const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
        duration: scrollDuration,
        delay: scrollDelay,
        smooth: smoothScroll,
        offset: scrollOffset
    });
};

export const scroll = (element: NavbarLink, handlerFunction: () => void | undefined) => {
    if (handlerFunction !== undefined) {
        handlerFunction();
    }
    scrollToElement(element);
};
