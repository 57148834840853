import s from './partners.module.sass';

import React, { FC } from 'react';
import Items from './Items/Items';

import languageSetter from '../../functions/i18n';

interface PartnersLocal {
    title: string
}

const Partners: FC = () => {
    const local: PartnersLocal = languageSetter.t('PARTNERS.translations');
    return (
        <div className={s.partners}>
            <div className={s.partners__title}>{local.title}</div>
            <Items/>
        </div>
    );
};

export default Partners;
