import React, { FC } from 'react';
import s from './round.module.sass';
import logo from '../../../../shared-ui/images/tokenomics/logo.svg';

interface IRound {
    className?: string
}

const Round: FC<IRound> = ({ className = '' }) => {
    return (
        <div className={`${s.round} ${className}`}>
            <svg viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="43.3062" cy="43.5426" r="42.2197" transform="rotate(-38 43.3062 43.5426)" stroke="url(#paint0_linear_1302_1584)"/>
                <defs>
                    <linearGradient id="paint0_linear_1302_1584" x1="43.3062" y1="0.822895" x2="43.3062" y2="86.2623" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5573AE"/>
                        <stop offset="0.5" stopColor="#34425D"/>
                        <stop offset="1" stopColor="#2A364B"/>
                    </linearGradient>
                </defs>
            </svg>
            <img className={s.round__logo} src={logo} />
        </div>
    );
};

export default Round;
