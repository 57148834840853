import React, { FC } from 'react';

interface FootnoteI {
    className?: string
}

const Footnote: FC<FootnoteI> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 144 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.733765 1.15485H134.874L143.48 25.1734" stroke="url(#paint0_linear_252_122)" strokeMiterlimit="10"/>
            <defs>
                <linearGradient id="paint0_linear_252_122" x1="0.733765" y1="12.9614" x2="144.057" y2="12.9614" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CD0003">
                        <animate attributeName="stop-color" values="#490000; #AD0101; #4D0202; #490000" dur="4s" repeatCount="indefinite"/>
                    </stop>
                    <stop offset="1" stopColor="#370405">
                        <animate attributeName="stop-color" values="#490000; #AD0101; #4D0202; #490000" dur="4s" repeatCount="indefinite"/>
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Footnote;
