import React, { Dispatch, FC, SetStateAction } from 'react';
import { ENavBarType } from '../../interfaces/navbar/navbarInterface';
import ButtonFrame from '../../shared-ui/components/Frames/ButtonFrame';
import s from './header.module.sass';
import redDotsImg from '../../shared-ui/images/header/dots.png';
import NavBar from '../Navbar/NavBar';
import languageSetter from '../../functions/i18n';
import landingVideo from '../../shared-ui/images/video/landing.mp4';

interface IHeader {
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    activeLang: string,
    homeLang: string,
    langChange: (lang: string) => Promise<void>
}

const Header: FC<IHeader> = ({ isOpen, setIsOpen, activeLang, homeLang, langChange }) => {
    const { ALL } = ENavBarType;
    const localTitle = languageSetter.t('HEADER.translations.title');
    const localButtonText = languageSetter.t('HEADER.translations.buttonText');
    const volumeButton = document.getElementById('volume-button') as HTMLButtonElement;
    const volumeIcons = document.querySelectorAll('use');
    const volumeMute = document.querySelector('use[href="#volume-mute"]');
    const volumeHigh = document.querySelector('use[href="#volume-high"]');
    const video = document.getElementById('video') as HTMLVideoElement;

    /**
    * Updates the volume icon so that it correctly reflects the volume of the video
     */
    const updateVolumeIcon = () => {
        volumeIcons.forEach((icon) => {
            icon.classList.add(s.header__hidden);
        });

        if (video.muted || video.volume === 0) {
            if (volumeMute) {
                volumeMute.classList.remove(s.header__hidden);
            }
        } else {
            if (volumeHigh) {
                volumeHigh.classList.remove(s.header__hidden);
            }
        }
    };

    /**
    * Mutes or unmutes the video when executed
     */
    const toggleMute = () => {
        video.muted = !video.muted;
    };

    if (volumeButton && video) {
        volumeButton.addEventListener('click', toggleMute);
        video.addEventListener('volumechange', updateVolumeIcon);
    }

    return (
        <header className={s.header} id='home'>
            <div className={s.header__image} />
            <video autoPlay loop muted id="video" className={s.header__video} >
                <source src={landingVideo} type="video/mp4"/>
            </video>
            <div className={s.header__videoControls} id="video-controls">
                <div className={s.header__volumeControls}>
                    <button className={s.header__volumeButton} id="volume-button">
                        <svg className={s.header__svg}>
                            <use href="#volume-mute"></use>
                            <use className={s.header__hidden} href="#volume-high"></use>
                        </svg>
                    </button>
                </div>
            </div>
            <svg style={{ display: 'none' }}>
                <defs>
                    <symbol id="volume-high" viewBox="0 0 24 24">
                        <path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>
                    </symbol>

                    <symbol id="volume-mute" viewBox="0 0 24 24">
                        <path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>
                    </symbol>
                </defs>
            </svg>
            <NavBar type={ALL} isOpen={isOpen} setIsOpen={setIsOpen}/>
            <img className={s.header__dots} src={redDotsImg} alt='red dots image' />
            <div className={s.header__info}>
                <h1 className={s.header__title}>{localTitle}</h1>
                <a className={s.header__link} href="https://app.evergaming.gg/">
                    <ButtonFrame>
                        {localButtonText}
                    </ButtonFrame>
                </a>
            </div>
        </header>
    );
};

export default Header;
