import React, { FC } from 'react';
import s from './roadmap.module.sass';
import RoadmapItems from './Items/RoadmapItems';
import { roadmap } from '../../shared-ui/constants/roadmap/roadmap';
import desctopTimeline from '../../shared-ui/images/roadmap/timeline.png';
import mobileTimeline from '../../shared-ui/images/roadmap/mobileTimeline.png';
import dot1 from '../../shared-ui/images/roadmap/dot1.png';
import dot2 from '../../shared-ui/images/roadmap/dot2.png';
import languageSetter from '../../functions/i18n';

interface RoadmapLocal {
    title: string
    Q2: []
    Q3: []
    Q4: []
    2023: []
}

const Roadmap: FC = () => {
    const local: RoadmapLocal = languageSetter.t('ROADMAP.translations');
    return (
        <div className={s.road} id='roadmap'>
            <div className={s.road__main}>
                <div className={s.road__name}>{ local.title}</div>
                <div className={s.road__years}>
                    <p>{roadmap.year1}</p>
                    <p>{roadmap.year2}</p>
                </div>
                <img src={desctopTimeline} alt={'timeline'} className={s.road__timeline}/>
                <div className={s.road__mobileline}>
                    <img src={mobileTimeline} alt={'timeline'} className={s.road__timelineMobile}/>
                    <div className={s.road__box}>
                        <div className={s.road__info}>
                            <div className={s.road__dots}>
                                <img src={dot1} alt={'dot'} className={s.road__dot}/>
                                <p className={s.road__yearsm}>{roadmap.year1}</p>
                            </div>
                            <div className={s.road__Q}>{roadmap.title1}</div>
                            <ol className={s.road__list}>
                                {local.Q2.map((text, index) =>
                                    <RoadmapItems
                                        key={index}
                                        text={text}
                                    />
                                )}
                            </ol>
                            <div className={s.road__Q}>{roadmap.title2}</div>
                            <ol className={s.road__list}>
                                {local.Q3.map((text, index) =>
                                    <RoadmapItems
                                        key={index}
                                        text={text}
                                    />
                                )}
                            </ol>
                        </div>
                        <div className={s.road__infoQ4}>
                            <div className={s.road__Q}>{roadmap.title3}</div>
                            <ol className={s.road__list}>
                                {local.Q4.map((text, index) =>
                                    <RoadmapItems
                                        key={index}
                                        text={text}
                                    />
                                )}
                            </ol>
                        </div>
                        <div className={s.road__info2023}>
                            <div className={s.road__dots}>
                                <img src={dot2} alt={'dot'} className={s.road__dot2}/>
                                <p className={s.road__yearsm}>{roadmap.year2}</p>
                            </div>
                            <ol className={s.road__Q0}>
                                {local['2023'].map((text, index) =>
                                    <RoadmapItems
                                        key={index}
                                        text={text}
                                    />
                                )}
                            </ol>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Roadmap;
