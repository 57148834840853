import React, { FC } from 'react';
import s from './cbt.module.sass';
import languageSetter from '../../functions/i18n';
import Block from './Block/Block';

import first from '../../shared-ui/images/everythingCBT/1.png';
import second from '../../shared-ui/images/everythingCBT/2.png';
import useWindowDimensions from '../../shared-ui/hooks/useWindowDimensions';

interface ICBTLocal {
    title: string,
    subTextArticle1: string,
    subTextArticle2: string,
    subTextArticle3: string
}

interface IEVGLocal {
    title: string,
    subTextArticle1: string,
    subTextArticle2: string,
}

const EverythingCBT: FC = () => {
    const [width] = useWindowDimensions();

    const localCBT:ICBTLocal = languageSetter.t('EVERYTHING_CBT.translations');
    const localEVG:IEVGLocal = languageSetter.t('EVG_SHARES.translations');

    return (
        <div className={s.cbt}>
            <Block turn={width <= 800} className={s.cbt__block} title={localCBT.title} src={first}>
                <p className={s.cbt__paragraph}>{localCBT.subTextArticle1}</p>
                <p className={s.cbt__paragraph}>{localCBT.subTextArticle2}</p>
                <p className={s.cbt__paragraph}>{localCBT.subTextArticle3} </p>
            </Block>
            <Block title={localEVG.title} src={second}>
                <p className={s.cbt__paragraph}>{localEVG.subTextArticle1}</p>
                <p className={s.cbt__paragraph}>{localEVG.subTextArticle2}</p>
            </Block>
        </div>
    );
};

export default EverythingCBT;
