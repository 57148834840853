import s from './intersection.module.sass';

import React, { FC } from 'react';
import languageSetter from '../../functions/i18n';

import map from '../../shared-ui/images/intersection/map.webp';

interface IntersectionLocal {
    title: string,
    subTextArticle1: string,
    subTextArticle2: string
}

const Intersection: FC = () => {
    const local: IntersectionLocal = languageSetter.t('WEB230.translations');

    return (
        <div className={s.intersection}>
            <div className={s.intersection__title}>{local.title}</div>
            <div className={s.intersection__content}>
                <div className={s.intersection__information}>
                    <div className={s.intersection__paragraph}>
                        {local.subTextArticle1}
                    </div>
                    <div className={s.intersection__line}/>
                    <div className={s.intersection__paragraph}>
                        {local.subTextArticle2}
                    </div>
                </div>
                <img className={s.intersection__map} src={map}/>
            </div>
        </div>
    );
};

export default Intersection;
