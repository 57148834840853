import s from './supportGames.module.sass';

import React from 'react';

import languageSetter from '../../functions/i18n';
import { Cards } from './Cards/Cards';

interface SupGamesLocal {
    title: string
}

const Speakers = () => {
    const local: SupGamesLocal = languageSetter.t('SUPPORT_GAMES.translations');
    return (
        <div className={s.supportGames}>
            <div className={s.supportGames__title}>{local.title}</div>
            <Cards/>
        </div>
    );
};

export default Speakers;
