import React from 'react';
import { Link } from 'react-router-dom';
import PolicyParser from '../../components/PolicyParser/PolicyParser';
import languageSetter from '../../functions/i18n';
import s from './privacy.module.sass';

interface IArticles {
    title: string
    text: string[]
}

interface IPolicyLocal {
    title: string
    back: string
    articles: IArticles[]
}

const Privacy = () => {
    const local: IPolicyLocal = languageSetter.t('PRIVACY.translations');

    return (
        <div className={s.privacy}>
            <Link to="/" className={s.privacy__back}>{local.back}</Link>
            <h1 className={s.privacy__title}>{local.title}</h1>
            <div className={s.privacy__content}>
                {local.articles.map(({ title, text }, index) => {
                    return (
                        <PolicyParser key={`article__${index}`} title={title} text={text}/>
                    );
                })}
            </div>
        </div>
    );
};

export default Privacy;
