import s from './items.module.sass';

import React, { FC } from 'react';

import { TEMP_PARTNERS } from '../../../shared-ui/constants/partners/partners';
import languageSetter from '../../../functions/i18n';

interface PartnersLocal {
    [index: string]: string;
}

const Items: FC = () => {
    const local:PartnersLocal = languageSetter.t('PARTNERS.translations');
    console.log(local);

    return (
        <div className={s.items}>
            {TEMP_PARTNERS.map(({ image, name }) => {
                return (
                    <div key={image} className={s.items__partner}>
                        <img className={s.items__img} src={image} alt={image}/>
                        <div>{local[name]}</div>
                    </div>
                );
            })}

        </div>
    );
};

export default Items;
