import React, { FC } from 'react';
import styles from '../../../shared-ui/styles/constants.module.sass';
import s from '../navbar.module.sass';
import { NavLink } from 'react-router-dom';

interface ICleanLinks {
    handleCloseNavBar: () => void
}

const CleanLinks: FC<ICleanLinks> = ({ handleCloseNavBar }) => {
    return (
        <>
            <NavLink to={'/'} onClick={handleCloseNavBar} className={`${styles.link} ${s.navbar__item} ${s.navbar__item_back}`}>
                <svg width="35" height="15" viewBox="0 0 35 10" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34 6.19853H1M1 6.19853L5.8 1M1 6.19853L5.8 11.3971" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                BACK
            </NavLink>
        </>
    );
};

export default CleanLinks;
