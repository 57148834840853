import React, { FC, useEffect } from 'react';
import NavBarItems from './Items/NavBarItems';
import s from './navbar.module.sass';
import { ENavBarType } from '../../interfaces/navbar/navbarInterface';

interface INavBar {
    isOpen: boolean,
    setIsOpen: (boolean: boolean) => void,
    type: ENavBarType
}

const NavBar: FC<INavBar> = ({ isOpen, setIsOpen, type }) => {
    useEffect(() => {
        isOpen ? document.documentElement.style.overflow = 'hidden' : document.documentElement.style.overflow = 'initial';
        isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'initial';
    }, [isOpen]);

    const handleOpenNavBar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${s.navbar} ${isOpen && s.navbar_open}`}>
            <NavBarItems type={type} isOpen={isOpen} setIsOpen={setIsOpen}/>
            <div className={s.navbar__toggle} onClick={handleOpenNavBar}>
                <div className={s.bar}>
                    {isOpen
                        ? <svg className={`${s.bar__burger} ${isOpen && s.bar__burger_open}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.54883 1.54956L18.2631 18.2638" stroke="#C6D1D4" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                            <path d="M1.54883 18.2638L18.2631 1.54956" stroke="#C6D1D4" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                        : <svg className={`${s.bar__burger} ${isOpen && s.bar__burger_open}`} viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="29" height="2"/>
                            <rect x="6" y="8" width="23" height="2"/>
                            <rect x="15" y="16" width="14" height="2"/>
                        </svg>}
                </div>
            </div>
        </div>
    );
};

export default NavBar;
