import React, { FC } from 'react';
import s from './borderFrame.module.sass';
import colors from '../../../shared-ui/styles/kit.module.sass';

interface IButtonFrame {
    children?: React.ReactNode,
}

const ButtonFrame: FC<IButtonFrame> = ({ children }) => {
    return (
        <div className={s.button}>
            <svg viewBox="0 0 296 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 61.4857V1H268.499L295 27.5143V88H27.5014L1 61.4857Z" stroke="url(#paint0_linear_122_126)" fill="url(#button-gradient)" strokeWidth="2"/>
                <defs>
                    <linearGradient id="paint0_linear_122_126" x1="148" y1="-24.5" x2="269.081" y2="144.942" gradientUnits="userSpaceOnUse">
                        <stop>
                            <animate attributeName="stop-color" values={`${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd2}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="0.416667">
                            <animate attributeName="stop-color" values={`${colors.buttonAdd}; ${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="1">
                            <animate attributeName="stop-color" values={`${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd}; ${colors.buttonMain}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                    </linearGradient>
                    <linearGradient id="button-gradient" x1="0%" y1="0%" x2="50%" y2="100%">
                        <stop offset="0%">
                            <animate attributeName="stop-color" values="#313E57; #5775B1; #313E57" dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="100%">
                            <animate attributeName="stop-color" values="#5775B1; #313E57; #5775B1" dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
            <p className={s.frame__p}>{children}</p>
        </div>
    );
};

export default ButtonFrame;
