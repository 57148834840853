import s from './doughnut.module.sass';
import React, { FC, useEffect, useState } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { options, TEMP_CHART_DATA, TEMP_DATA } from '../../../shared-ui/constants/chart/chart';
import useWindowDimensions from '../../../shared-ui/hooks/useWindowDimensions';
import Footnotes from '../Footnotes/Footnotes';
import Round from './Round/Round';

const Doughnut: FC = () => {
    const [changed, setChanged] = useState(false);
    const [width] = useWindowDimensions();

    useEffect(() => {
        // cutout adaptive
        TEMP_CHART_DATA.datasets[0].cutout = width / 100 * (width > 800 ? 4 : 11);
        setChanged(false);
    }, [width]);

    useEffect(() => {
        setChanged(true);
    }, [changed]);

    return (
        <div className={s.doughnut}>
            <Footnotes footnotes={TEMP_DATA}/>
            <div className={s.doughnut__grafic}>
                {changed && <DoughnutChart className={s.doughnut__chart} options={options} data={TEMP_CHART_DATA}/>}
                <Round className={s.doughnut__round}/>
            </div>
        </div>
    );
};

export default Doughnut;
