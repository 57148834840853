import React, { FC } from 'react';

interface IBorderLeftBottom {
    className?: string
}

const BorderLeftBottom: FC<IBorderLeftBottom> = ({ className = '' }) => {
    return (
        <svg className={className} viewBox="0 0 607 216" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0V193.414L20.2071 215H607" stroke="url(#paint0_linear_1247_1338)" strokeWidth="2"/>
            <defs>
                <linearGradient id="paint0_linear_1247_1338" x1="34.5002" y1="149" x2="657.876" y2="269.012" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5573AE"/>
                    <stop offset="0.416667" stopColor="#34425D"/>
                    <stop offset="1" stopColor="#152033"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default BorderLeftBottom;
