import React, { FC } from 'react';
import s from './ecosystem.module.sass';
import EcoFrame from '../../shared-ui/components/Frames/EcoFrame';
import ADS from '../../shared-ui/images/icons/ADS.png';
import viewers from '../../shared-ui/images/icons/viewers.png';
import gamepad from '../../shared-ui/images/icons/gamepad.png';
import eventManager from '../../shared-ui/images/icons/event-manager.png';
import influence from '../../shared-ui/images/icons/influence.png';
import logo from '../../shared-ui/images/icons/logo-ecosystem.svg';

import parallax from '../../shared-ui/images/parallaxes/ecosystem.svg';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';

import languageSetter from '../../functions/i18n';
import EcoFrameBackground from '../../shared-ui/components/Frames/EcoFrameBackground';

const Ecosystem: FC = () => {
    const ecosystemItems = [{ src: influence, text: languageSetter.t('ECOSYSTEM.translations.influencers') }, { src: viewers, text: languageSetter.t('ECOSYSTEM.translations.viewers') }, { src: ADS, text: languageSetter.t('ECOSYSTEM.translations.advertisersAndSponsors') }, { src: gamepad, text: languageSetter.t('ECOSYSTEM.translations.playersAndTeams') }, { src: eventManager, text: languageSetter.t('ECOSYSTEM.translations.eventManagers') }];
    const localTitle = languageSetter.t('ECOSYSTEM.translations.title');
    let classJapan = '';
    if (languageSetter.language === '日本語') {
        classJapan = s.ecosystem__arrows_japan;
    }
    return (
        <div className={s.ecosystem}>
            <MouseParallaxContainer
                className='parallax'
                useWindowMouseEvents={true}
                resetOnLeave
            >
                <MouseParallaxChild
                    factorX={0.06}
                    factorY={0.06}
                >
                    <img className={s.ecosystem__parallax} src={parallax} alt='parallax'/>
                </MouseParallaxChild>

                <h1 className={s.ecosystem__title}>{localTitle}</h1>
                <div className={s.ecosystem__directions}>
                    <div className={`${s.ecosystem__arrows} ${classJapan}`}/>
                </div>
                <div className={s.ecosystem__frames}>
                    <div className={s.ecosystem__items}>
                        {ecosystemItems.map((item) =>
                            <div key={item.src} className={s.ecosystem__item}>
                                <EcoFrame>
                                    <EcoFrameBackground />
                                    <div className={s.ecosystem__frame}>
                                        <img src={item.src}/>
                                        <h6 className={s.ecosystem__textItem}>{item.text}</h6>
                                    </div>
                                </EcoFrame>
                            </div>
                        )}
                    </div>
                    <div className={s.ecosystem__logoItem}>
                        <img className={s.ecosystem__logo} src={logo} />
                    </div>
                </div>
            </MouseParallaxContainer>

        </div>

    );
};

export default Ecosystem;
