import React, { FC } from 'react';
import daoImage from '../../shared-ui/images/dao/dao-image.png';

import s from './dao.module.sass';
import languageSetter from '../../functions/i18n';

const Dao: FC = () => {
    const local = languageSetter.t('DAO.translations.text');
    return (
        <div className={s.dao}>
            <div className={s.dao__lineUpper} />
            <div className={s.dao__content}>
                <img className={s.dao__image} src={daoImage} alt='decoration' />
                <p className={s.dao__text}>
                    {local}
                </p>
            </div>
            <div className={s.dao__background} />
            <div className={s.dao__lineBottom} />
        </div>
    );
};

export default Dao;
