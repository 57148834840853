import React, { FC } from 'react';
import s from './borderFrame.module.sass';
import colors from '../../styles/kit.module.sass';
import languageSetter from '../../../functions/i18n';

interface ITableFrame {
    children?: React.ReactNode,
}

const TableFrame: FC<ITableFrame> = ({ children }) => {
    let japBox = '';
    if (languageSetter.language !== 'Eng') {
        japBox = s.table__japanBox;
    }
    return (
        <div className={s.table}>
            <svg className={japBox} viewBox="0 0 311 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1V39L20 61H99L45.5 1H1Z" fill="url(#paint0_linear_15_13)"/>
                <path d="M1 39.4142V1H290.793L310 22.5858V61H20.2071L1 39.4142Z" stroke="url(#paint0_linear_215_305)" strokeWidth="2"/>
                <path d="M45.501 59.5L12.501 23" stroke="url(#paint0_linear_215_305)"/>
                <defs>
                    <linearGradient id="paint0_linear_215_305" x1="105" y1="-17.1724" x2="191.489" y2="102.985" gradientUnits="userSpaceOnUse">
                        <stop>
                            <animate attributeName="stop-color" values={`${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd2}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="0.416667">
                            <animate attributeName="stop-color" values={`${colors.buttonAdd}; ${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="1">
                            <animate attributeName="stop-color" values={`${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd}; ${colors.buttonMain}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                    </linearGradient>
                    <linearGradient id="paint0_linear_15_13" x1="1" y1="103" x2="84.5" y2="38.5" gradientUnits="userSpaceOnUse">
                        <stop offset="0.078125" stopColor="#1F2D46"/>
                        <animate attributeName="stop-color" values={`${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd2}`} dur="4s" repeatCount="indefinite"></animate>
                        <stop offset="1" stopColor="#24314D" stopOpacity="0.41"/>
                    </linearGradient>
                </defs>
            </svg>
            <div className={s.frame__p}>{children}</div>
        </div>
    );
};

export default TableFrame;
