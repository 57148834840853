import React, { FC } from 'react';
import TableFrame from '../../shared-ui/components/Frames/TableFrame';
import s from './benefits.module.sass';
import languageSetter from '../../functions/i18n';

const Benefits: FC = () => {
    const tableItemTexts = [
        languageSetter.t('BENEFITS.translations.NFT_Marketplace'),
        languageSetter.t('BENEFITS.translations.lootBoxes'),
        languageSetter.t('BENEFITS.translations.vote'),
        languageSetter.t('BENEFITS.translations.staking'),
        languageSetter.t('BENEFITS.translations.yieldFarming'),
        languageSetter.t('BENEFITS.translations.betting'),
        languageSetter.t('BENEFITS.translations.miniGames'),
        languageSetter.t('BENEFITS.translations.tournaments'),
        languageSetter.t('BENEFITS.translations.decisionMaking'),
        languageSetter.t('BENEFITS.translations.sweepstakes'),
        languageSetter.t('BENEFITS.translations.challenges'),
        languageSetter.t('BENEFITS.translations.league')
    ];
    const localTitle = languageSetter.t('BENEFITS.translations.title');
    let clazz = '';
    if (languageSetter.language !== 'Eng') {
        clazz = s.benefits__japan;
    }
    return (
        <div className={s.benefits__wrap}>
            <div className={s.benefits} id='benefits'>
                <p className={s.benefits__title}>{localTitle}</p>
                <div className={s.benefits__table}>
                    {tableItemTexts.map((textItem) =>
                        <TableFrame key={textItem}>
                            <p className={`${s.benefits__text} ${clazz}`}>{textItem}</p>
                        </TableFrame>
                    )}
                </div>
            </div>
        </div>

    );
};

export default Benefits;
