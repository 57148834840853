import s from './information.module.sass';

import React, { FC } from 'react';

import image from '../../../shared-ui/images/tokenomics/information.png';
import Background from './Background/Background';
import languageSetter from '../../../functions/i18n';
import useWindowDimensions from '../../../shared-ui/hooks/useWindowDimensions';

interface InformationLocal {
    'title': string,
    'farmingAndRewards': string,
    'treasury_DAO': string,
    'team': string,
    'partnerships': string,
    'marketing': string,
    'privateSale': string,
    'liquidity': string,
    'advisors': string,
    'publicSale': string,
    'subText': string,
}

const Information: FC = () => {
    const [width] = useWindowDimensions();

    const local: InformationLocal = languageSetter.t('TOKENOMICS.translations');

    return (
        <Background mobile={width < 800} className={s.information}>
            <div className={s.information__inner}>
                <img className={s.information__image} src={image}/>
                <div className={s.information__text}>
                    {local.subText}
                </div>
            </div>
        </Background>
    );
};

export default Information;
