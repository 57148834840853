import PUBGMobile from '../../images/supportGames/PUBGMobile.webp';
import ApexM from '../../images/supportGames/ApexM.webp';
import PUBG from '../../images/supportGames/PUBG.webp';
import brawlstars from '../../images/supportGames/brawlstars.webp';
import CoDm from '../../images/supportGames/CoDm.webp';
import clashroyale from '../../images/supportGames/clashroyale.webp';
import CSGO from '../../images/supportGames/CSGO.webp';
import dota2 from '../../images/supportGames/dota2.webp';
import fortnite from '../../images/supportGames/fortnite.webp';
import freefire from '../../images/supportGames/freefire.webp';
import minecraft from '../../images/supportGames/minecraft.webp';
import mushroomswars2 from '../../images/supportGames/mushroomswars2.webp';
import starcraft from '../../images/supportGames/starcraft.webp';
import Vector from '../../images/supportGames/Vector.webp';
import wildrift from '../../images/supportGames/wildrift.webp';
import Heartstone from '../../images/supportGames/Heartstone.webp';

export const TEMP_GAMES_MOBILE = [
    {
        image: PUBGMobile
    },
    {
        image: freefire
    },
    {
        image: clashroyale
    },
    {
        image: wildrift
    },
    {
        image: mushroomswars2
    },
    {
        image: brawlstars
    },
    {
        image: ApexM
    },
    {
        image: CoDm
    },
    {
        image: Heartstone
    }
];

export const TEMP_GAMES_DESKTOP = [
    {
        image: CSGO
    },
    {
        image: dota2
    },
    {
        image: starcraft
    },
    {
        image: minecraft
    },
    {
        image: PUBG

    },
    {
        image: Vector
    },
    {
        image: fortnite
    }
];
