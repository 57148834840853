import './shared-ui/styles/reset.sass';

import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Main from './pages/Main/Main';
import ScrollToTop from './functions/ScrollToTop';
import Privacy from './pages/Privacy/Privacy';
import Cookie from './pages/Cookie/Cookie';

const App: FC = () => {
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path={'/'} element={<Main />}/>
                <Route path={'/:lang/'} element={<Main />}/>
                <Route path={'/cookie-policy'} element={<Cookie />}/>
                <Route path={'/:lang/cookie-policy'} element={<Cookie />}/>
                <Route path={'/privacy-policy'} element={<Privacy />}/>
                <Route path={'/:lang/privacy-policy'} element={<Privacy />}/>
            </Routes>
        </Router>
    );
};

export default App;
