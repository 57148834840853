import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../shared-ui/styles/constants.module.sass';
import s from '../navbar.module.sass';
import { navbarLangs, NavbarLink, navbarMainText } from '../../../shared-ui/constants/navbar/navbar';
import languageSetter from '../../../functions/i18n';
import NavBarLang from './NavBarLang';
import SocialLinks from '../../SocialLinks/SocialLinks';

interface IAllLinks {
    scroll: (element: NavbarLink) => void,
    langChange: (lang: string) => void,
    activeLang: string
}

const AllLinks: FC<IAllLinks> = ({ scroll, langChange, activeLang }) => {
    const navigate = useNavigate();

    const navigateScroll = (navbarLink: NavbarLink) => {
        scroll(navbarLink);

        navigate(`#${navbarLink}`);
    };

    return (
        <>
            {navbarMainText.map((link, index) => {
                const local = languageSetter.t(`NavLINKS.translations.${link}`);
                const scrollTo = () => navigateScroll(link);
                if (link === NavbarLink.EnterPlatform) {
                    return (
                        <a key={index} href={'https://app.evergaming.gg/tournaments'} className={`${styles.link} ${s.navbar__item}`}>{local}</a>
                    );
                }
                return (
                    <a key={index} onClick={scrollTo} className={`${styles.link} ${s.navbar__item}`}>{local}</a>
                );
            })}
            <div className={s.navbar__langs_mobile}>
                {navbarLangs.map((lang, index) => {
                    return (
                        <NavBarLang onClick={() => langChange(lang)}
                            className={activeLang === lang ? s.navbar__langs_active : s.navbar__langs_mobile_item}
                            key={Math.random()} index={index} lang={lang}/>
                    );
                })}
            </div>
            <div className={s.navbar__socialsMobile}>
                <SocialLinks/>
            </div>
        </>
    );
};

export default AllLinks;
