import React, { FC } from 'react';
import { navbarLangs } from '../../../shared-ui/constants/navbar/navbar';
import s from '../navbar.module.sass';
import styles from '../../../shared-ui/styles/constants.module.sass';

interface INavBarLang {
    lang: string;
    index: number;
    onClick: () => void;
    className: string;
}

const NavBarLang: FC<INavBarLang> = ({ lang, index, onClick, className }) => {
    return (
        <>
            <div onClick={onClick} className={`${styles.link} ${className}`}>
                {lang}
            </div>
            {navbarLangs.length - 1 !== index && <div className={s.navbar__separator}> | </div>}
        </>

    );
};

export default NavBarLang;
