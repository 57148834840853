import { IFootnote, LocalInformation } from '../../../shared-ui/constants/chart/chart';
import s from '../Doughnut/doughnut.module.sass';
import Footnote from '../../../shared-ui/components/Footnote/Footnote';
import React, { FC } from 'react';
import languageSetter from '../../../functions/i18n';

interface IFootnotes {
    footnotes: IFootnote[]
}

const Footnotes: FC<IFootnotes> = ({ footnotes }) => {
    const local: LocalInformation = languageSetter.t('TOKENOMICS.translations');

    return (
        <>
            {footnotes.map(({ present, label }, index) =>
                <div key={index} className={s.doughnut__footnote}>
                    <div className={s.doughnut__present}>{index >= 4 ? `${local[label]} ${present} %` : `${present} % ${local[label]}`}</div>
                    <Footnote className={s.doughnut__line}/>
                </div>
            )}
        </>
    );
};

export default Footnotes;
