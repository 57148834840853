import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import translationEN from '../stores/translations/en_US.json';
import translationID from '../stores/translations/id.json';
import translationJP from '../stores/translations/ja.json';
import translationKO from '../stores/translations/ko.json';

i18n.use(Backend)
    .use(detector) // Browser Language Detector
    .use(initReactI18next)
    .init({
        resources: {
            ENG: {
                common: translationEN
            },
            IND: {
                common: translationID
            },
            日本語: {
                common: translationJP
            },
            한국어: {
                common: translationKO
            }
        },
        fallbackLng: 'ENG', // Fallback Language: English

        ns: ['common'],

        defaultNS: 'common',
        returnObjects: true,

        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });
export default i18n;
