import React, { FC, useEffect, useState } from 'react';
import Ecosystem from '../../components/Ecosystem/Ecosystem';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { scrollToElement } from '../../functions/ScrollToElement';
import SupportGames from '../../components/SupportGames/supportGames';
import Chart from '../../components/Chart/Chart';
import Partners from '../../components/Partners/Partners';
import Dao from '../../components/Dao/Dao';
import Benefits from '../../components/Benefits/Benefits';
import SubFooter from '../../components/SubFooter/SubFooter';
import Intersection from '../../components/Intersection/Intersection';
import EverythingCBT from '../../components/EverythingCBT/EverythingCBT';
import WhitePaper from '../../components/WhitePaper/WhitePaper';
import Roadmap from '../../components/Roadmap/Roadmap';
import PathSetter from '../../functions/PathSetter';
import { useNavigate, useParams } from 'react-router-dom';
import languageSetter from '../../functions/i18n';
import Background from '../../components/Background/Background';

const Main: FC = () => {
    const [activeLang, setActiveLang] = useState('ENG');
    const [homeLang, setHomeLang] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const langChange = async (lang: string) => {
        localStorage.setItem('lang', lang);
        languageSetter.changeLanguage(lang);
        setActiveLang(lang);

        await PathSetter(lang, setHomeLang, navigate, params.lang);
        if (setIsOpen) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        scrollToElement(window.location.hash.replace('#', ''));
    }, []);
    return (
        <>
            <Header isOpen={isOpen} setIsOpen={setIsOpen} activeLang={activeLang} homeLang={homeLang}
                langChange={langChange}/>
            <Dao/>
            <Benefits/>
            <EverythingCBT/>
            <Ecosystem/>
            <Chart/>
            <Intersection/>
            <SupportGames/>
            <Partners/>
            <Roadmap/>
            <WhitePaper/>
            <SubFooter/>
            <Footer/>
            <Background/>
        </>
    );
};

export default Main;
