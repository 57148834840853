const PathSetter = async (
    lang: string,
    setHomeLang: (pathLang:string) => void,
    navigate: (pathLang:string) => void, params: string | undefined) => {
    let pathLang = '';
    switch (lang) {
    case 'ENG':
        pathLang = '';
        break;
    case '日本語':
        pathLang = 'jp';
        break;
    case '한국어':
        pathLang = 'ko';
        break;
    case 'IND':
        pathLang = 'in';
        break;
    default:
        pathLang = '';
        break;
    }
    const hash = window.location.hash;

    setHomeLang(pathLang);

    let path = '';

    if (params !== undefined) {
        path = window.location.pathname.replace(`${params}`, `${pathLang}`);
    } else {
        path = pathLang + window.location.pathname;
        if (path.slice(-1) === '/') {
            path = path.substring(0, path.length - 1);
        }
    }

    const pathnameNew = path.replace('//', '/') + hash;

    navigate(pathnameNew);
};

export default PathSetter;
