import { FootnoteLabels } from './footnoteLabels';
import colors from '../../../shared-ui/styles/kit.module.sass';

export interface LocalInformation {
    farmingAndRewards: string
    treasury_DAO: string
    team: string
    partnerships: string
    marketing: string
    privateSale: string
    liquidity: string
    advisors: string
    publicSale: string
}

export interface IFootnote {
    label: FootnoteLabels,
    present: number
}

export const TEMP_DATA = [
    {
        label: FootnoteLabels.PARTNERSHIPS,
        present: 10
    },
    {
        label: FootnoteLabels.PUBLIC_SALE,
        present: 2
    },
    {
        label: FootnoteLabels.TEAM,
        present: 12
    },
    {
        label: FootnoteLabels.TREASURY_DAO,
        present: 21
    },
    {
        label: FootnoteLabels.MARKETING,
        present: 10
    },
    {
        label: FootnoteLabels.PRIVATE_SALE,
        present: 10
    },
    {
        label: FootnoteLabels.FARMING_AND_REWARDS,
        present: 25
    },
    {
        label: FootnoteLabels.ADVISORS,
        present: 3
    },
    {
        label: FootnoteLabels.LIQUIDITY,
        present: 7
    }
];

export const TEMP_CHART_DATA = {
    labels: [],
    datasets: [{
        label: '',
        data: TEMP_DATA.map(({ present }) => present),
        backgroundColor: [
            colors.TokenColor4,
            colors.TokenColor5,
            colors.TokenColor6,
            colors.TokenColor7,
            colors.TokenColor8,
            colors.TokenColor9,
            colors.TokenColor3,
            colors.TokenColor2,
            colors.TokenColor1
        ],
        borderWidth: 0,
        cutout: 65,
        hoverOffset: 4
    }]
};

export const options = {
    plugins: {
        tooltip: {
            enabled: false
        }
    }
};
