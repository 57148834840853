import React from 'react';
import { Link } from 'react-router-dom';
import PolicyParser from '../../components/PolicyParser/PolicyParser';
import languageSetter from '../../functions/i18n';
import s from './cookie.module.sass';
import arrow from '../../shared-ui/images/icons/backArrow2.svg';

interface IArticles {
    title: string
    text: string[]
}

interface IPolicyLocal {
    title: string
    subTitle: string
    back: string
    articles: IArticles[]
}

const Cookie = () => {
    const local: IPolicyLocal = languageSetter.t('COOKIE.translations');

    return (
        <div className={s.cookie}>
            <Link to="/" className={s.cookie__back}><img src={arrow}/> {local.back}</Link>
            <h1 className={s.cookie__title}>{local.title}</h1>
            <h2 className={s.cookie__subtitle}>{local.subTitle}</h2>
            <div className={s.cookie__content}>
                {local.articles.map(({ title, text }, index) => {
                    return (
                        <PolicyParser key={`article__${index}`} title={title} text={text}/>
                    );
                })}
            </div>
        </div>
    );
};

export default Cookie;
