import React, { FC } from 'react';
import languageSetter from '../../functions/i18n';

import s from './subFooter.module.sass';

import background from '../../shared-ui/images/header/background-mobile.png';
import dots from '../../shared-ui/images/header/dots.png';
import subFooterVideo from '../../shared-ui/images/video/subFooter.mp4';
import ButtonFrame from '../../shared-ui/components/Frames/ButtonFrame';

interface SubFooterLocal {
    title: string,
    subText: string,
    start: string
}

const SubFooter: FC = () => {
    const local: SubFooterLocal = languageSetter.t('FOOTER.translations');

    return (
        <div className = {s.subFooter}>
            <video autoPlay loop muted id="video" className={s.subFooter__video} >
                <source src={subFooterVideo} type="video/mp4"/>
            </video>
            <img className={s.subFooter__background} src={background} />
            <img className={s.subFooter__benefit} src={dots} />
            <div className={`${s.subFooter__title} ${s.subFooter__title_disappear}`}>{local.title}</div>
            <div className={`${s.subFooter__title} ${s.subFooter__accent}`}>{local.subText}</div>
            <a className={s.subFooter__link} href="https://app.evergaming.gg/">
                <div className={s.subFooter__button}>
                    <ButtonFrame>{local.start}</ButtonFrame>
                </div>
            </a>
        </div>
    );
};

export default SubFooter;
