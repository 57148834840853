import React, { FC } from 'react';
import s from '../roadmap.module.sass';

interface IRoadmapItems {
    text: string
}

const RoadmapItems: FC<IRoadmapItems> = ({ text }) => {
    return (
        <li className={s.whom__list}>
            {text}
        </li>
    );
};

export default RoadmapItems;
