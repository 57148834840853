import React, { FC } from 'react';
import style from './borderFrame.module.sass';

const EcoFrameBackground: FC = () => {
    return (
        <div className={style.ecoBackground}>
            <svg viewBox="0 0 129 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 64.0154V0H112.029L129 16.9846V81H16.9706L0 64.0154Z" fill="url(#paint0_linear_1247_585)" strokeWidth="2"/>
                <defs>
                    <linearGradient id="paint0_linear_1247_585" x1="103" y1="104.5" x2="37" y2="31.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor='#263858'/>
                        <stop offset="1" stopColor='#113068' stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default EcoFrameBackground;
