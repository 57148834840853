import React, { FC } from 'react';
import languageSetter from '../../functions/i18n';
import s from './parser.module.sass';

interface IPolicy {
  title: string
  text: string[]
}

const PolicyParser: FC<IPolicy> = ({ title, text }) => {
    return (
        <div className={s.policy}>
            <h2 className={s.policy__title}>{title}</h2>
            <p className={s.policy__text}>{text}</p>
        </div>
    );
};

export default PolicyParser;
