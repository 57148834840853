import React, { FC } from 'react';
import discordIcon from '../../shared-ui/images/icons/discord.svg';
import telegramIcon from '../../shared-ui/images/icons/telegram.svg';
import twitterIcon from '../../shared-ui/images/icons/twitter.svg';
import s from './sociallinks.module.sass';

const SocialLinks: FC = () => {
    return (
        <ul className={s.links}>
            <li className={s.links__discord}>
                <a target="_blank" href="https://discord.com/invite/hCZt3Eby87" rel="noreferrer">
                    <img src={discordIcon} alt="telegram icon" />
                </a>
            </li>
            <li className={s.links__telegram}>
                <a target="_blank" href="https://t.me/EverGaming" rel="noreferrer">
                    <img src={telegramIcon} alt="vk icon" />
                </a>
            </li>
            <li className={s.links__twitter}>
                <a target="_blank" href="https://twitter.com/evergaming_io" rel="noreferrer">
                    <img src={twitterIcon} alt="discord icon" />
                </a>
            </li>
        </ul>
    );
};

export default SocialLinks;
