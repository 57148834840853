import enUS from '../../../stores/translations/en_US.json';

/* eslint-disable no-unused-vars */
export enum NavbarLink {
    Home = 'home',
    Benefits = 'benefits',
    Tokenomics = 'tokenomics',
    Roadmap = 'roadmap',
    WhitePaper = 'whitePaper',
    EnterPlatform = 'enterPlatform'
}

export const navbarLangs = [
    'ENG', '日本語', '한국어', 'IND'
];

export const navbarMainText = [
    NavbarLink.Home,
    NavbarLink.Benefits,
    NavbarLink.Tokenomics,
    NavbarLink.Roadmap,
    NavbarLink.WhitePaper,
    NavbarLink.EnterPlatform
];

export const navbarText = {
    BACK: enUS.NavLINKS.translations.back,
    EVERGAMING: 'Evergaming'
};

export const scrollDelay: number = 200;
export const scrollDuration: number = 1300;
export const smoothScroll: boolean = true;
export const scrollOffset: number = -20;
