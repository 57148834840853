export enum FootnoteLabels {
    'PARTNERSHIPS' = 'partnerships',
    'PUBLIC_SALE' = 'publicSale',
    'TEAM' = 'team',
    'TREASURY_DAO' = 'treasury_DAO',
    'MARKETING' = 'marketing',
    'PRIVATE_SALE' = 'privateSale',
    'FARMING_AND_REWARDS' = 'farmingAndRewards',
    'ADVISORS' = 'advisors',
    'LIQUIDITY' = 'liquidity',
}
