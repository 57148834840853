import bitmelech from '../../images/partners/bitmelech.svg';
import Broxus from '../../images/partners/Broxus.svg';
import C2M from '../../images/partners/C2M.svg';
import GB from '../../images/partners/GB.svg';
import everscale from '../../images/partners/everscale.svg';
import Warp from '../../images/partners/Warp.svg';

export const TEMP_PARTNERS = [
    {
        image: everscale,
        name: 'everscale'
    },
    {
        image: Broxus,
        name: 'broxus'
    },
    {
        image: Warp,
        name: 'warp'
    },
    {
        image: C2M,
        name: 'c2m'
    },
    {
        image: bitmelech,
        name: 'bitmelech'
    },
    {
        image: GB,
        name: 'grandbazar'
    }
];
