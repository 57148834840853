import s from './cards.module.sass';

import React, { FC } from 'react';
import { TEMP_GAMES_MOBILE, TEMP_GAMES_DESKTOP } from '../../../shared-ui/constants/supportGames/supportGames';
import Card from './Card/Card';

export const Cards: FC = () => {
    return (
        <div className={s.cards__card}>
            <div className={s.cards__games}>
                {[...TEMP_GAMES_MOBILE, ...TEMP_GAMES_DESKTOP].map(({ image }) => {
                    return <Card key={image} image={image} />;
                })}
            </div>
        </div>
    );
};
