import React, { FC } from 'react';
import style from './borderFrame.module.sass';
import colors from '../../styles/kit.module.sass';

interface IEcoFrame {
    children?: React.ReactNode,
}

const EcoFrame: FC<IEcoFrame> = ({ children }) => {
    return (
        <div className={style.eco}>
            <svg viewBox="0 0 148 192" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m1,92.5l0,76.914l19.2071,21.586l126.7929,0l0,-168.4142l-19.207,-21.5858l-126.793,0l0,91.56395" stroke="url(#paint0_linear_1247_592)" strokeWidth="2"/>
                <defs>
                    <linearGradient id="paint0_linear_1247_592" y2="1.21947" x2="0.96581" y1="0.43491" x1="0.42123" gradientUnits="userSpaceOnUse">
                        <stop>
                            <animate attributeName="stop-color" values={`${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd2}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="0.416667">
                            <animate attributeName="stop-color" values={`${colors.buttonAdd}; ${colors.buttonAdd2}; ${colors.buttonMain}; ${colors.buttonAdd}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="1">
                            <animate attributeName="stop-color" values={`${colors.buttonMain}; ${colors.buttonAdd}; ${colors.buttonAdd}; ${colors.buttonMain}`} dur="4s" repeatCount="indefinite"></animate>
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
            <p className={style.frame__p}>{children}</p>
        </div>
    );
};

export default EcoFrame;
