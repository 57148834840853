import ButtonFrame from '../../shared-ui/components/Frames/ButtonFrame';
import React, { FC } from 'react';
import s from './whitePaper.module.sass';
import languageSetter from '../../functions/i18n';

interface WhitePaperLocal {
    title: string
}
const WhitePaper: FC = () => {
    const local: WhitePaperLocal = languageSetter.t('WHITE_PAPER.translations');
    return (
        <a href={'https://evergaming-media.fra1.digitaloceanspaces.com/web/evergaming_whitepaper.pdf'} className={s.wp} id='whitePaper'>
            <ButtonFrame>{local.title}</ButtonFrame>
        </a>
    );
};

export default WhitePaper;
