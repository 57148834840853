import s from './block.module.sass';
import React, { FC, ReactNode } from 'react';
import BorderLeftBottom from './BorderLeftBottom/BorderLeftBottom';

interface IBlock {
    className?: string,
    turn?: boolean,
    children: ReactNode,
    title: string,
    src: string
}

const Block: FC<IBlock> = ({ children, title, src, className = '', turn = false }) => {
    return (
        <div className={`${s.block} ${className}`}>
            <div className={`${s.block__information} ${turn ? s.block__information_turn : ''}`}>
                <div className={s.block__title}>{title}</div>
                <div className={s.block__text}>{children}</div>
            </div>
            <img className={`${s.block__image} ${turn ? s.block__image_turn : ''}`} src={src} alt={title}/>
            <div className={`${s.block__benefits} ${turn ? s.block__benefits_turn : ''}`}>
                <BorderLeftBottom className={`${s.block__border} ${turn ? s.block__border_turn : ''}`}/>
            </div>
        </div>
    );
};

export default Block;
