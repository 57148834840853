import React, { FC } from 'react';
import s from './background.module.sass';

interface IButtonFrame {
    children?: React.ReactNode,
    className?: string,
    mobile?: boolean
}

const Background: FC<IButtonFrame> = ({ children, className, mobile = false }) => {
    return (
        <div className={`${s.background} ${className}`}>
            {mobile
                ? <svg className={s.background__svg} viewBox="0 0 316 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 112.967V1H305.805L314.732 11.0328V123H9.92724L1 112.967Z" stroke="url(#paint0_linear_1302_1588)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1302_1588" x1="30.0493" y1="36.3241" x2="319.787" y2="92.1041" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5573AE"/>
                            <stop offset="0.416667" stopColor="#34425D"/>
                            <stop offset="1" stopColor="#152033"/>
                        </linearGradient>
                    </defs>
                </svg>
                : <svg className={s.background__svg} viewBox="0 0 677 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 121.414V1H656.793L676 22.5858V143H20.2071L1 121.414Z" stroke="url(#paint0_linear_1247_1335)" strokeWidth="2"/>
                    <defs>
                        <linearGradient id="paint0_linear_1247_1335" x1="63.4999" y1="77.0004" x2="686.875" y2="197.012" gradientUnits="userSpaceOnUse">
                            <stop>
                                <animate attributeName="stop-color" values={'#5573AE; #34425D; #152033; #5573AE;'} dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="0.416667">
                                <animate attributeName="stop-color" values={'#34425D; #152033; #5573AE; #34425D;'} dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="1">
                                <animate attributeName="stop-color" values={'#5573AE; #34425D; #152033; #5573AE;'} dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                        </linearGradient>
                    </defs>
                </svg>
            }

            <p className={s.background__inner}>{children}</p>
        </div>
    );
};

export default Background;
