import s from './card.module.sass';

import React, { FC } from 'react';

interface ICard {
    image: string,
}

const Card: FC<ICard> = ({ image }) => {
    return (
        <img className={s.card} src={image} alt={image}/>
    );
};

export default Card;
