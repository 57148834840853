import React, { FC } from 'react';

import s from './background.module.sass';

import image1 from '../../shared-ui/images/background/1.svg';
import image2 from '../../shared-ui/images/background/2.svg';
import image3 from '../../shared-ui/images/background/3.svg';
import image4 from '../../shared-ui/images/background/4.svg';
import image5 from '../../shared-ui/images/background/5.svg';
import image6 from '../../shared-ui/images/background/tokenomics/1.svg';

const Background: FC = () => {
    return (
        <div className={s.background}>
            <img className={`${s.background__image} ${s.background__image_1}`} src={image1}/>
            <img className={`${s.background__image} ${s.background__image_2}`} src={image2}/>
            <img className={`${s.background__image} ${s.background__image_3}`} src={image3}/>
            <img className={`${s.background__image} ${s.background__image_4}`} src={image6}/>
            <img className={`${s.background__image} ${s.background__image_5}`} src={image1}/>
            <img className={`${s.background__image} ${s.background__image_6}`} src={image4}/>
            <img className={`${s.background__image} ${s.background__image_7}`} src={image5}/>
            <img className={`${s.background__image} ${s.background__image_8}`} src={image3}/>
            <img className={`${s.background__image} ${s.background__image_9}`} src={image4}/>
            <img className={`${s.background__image} ${s.background__image_10}`} src={image1}/>
        </div>
    );
};

export default Background;
