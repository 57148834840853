import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../shared-ui/images/icons/logo-ecosystem.svg';
import s from './footer.module.sass';
import languageSetter from '../../functions/i18n';

interface IFooter {
}

const Footer: FC<IFooter> = () => {
    const policyLocal = languageSetter.t('PRIVACY.translations.title');
    const cookieLocal = languageSetter.t('COOKIE.translations.title');

    return (
        <footer className={s.footer}>
            <Link to={'/'}><img className={s.footer__logo} src={logo} alt='logo'/></Link>

            <div className={s.footer__rights}>
                <Link className={s.footer__link} to={'/cookie-policy'}>{cookieLocal}</Link>
                <Link className={s.footer__link} to={'/privacy-policy'}>{policyLocal}</Link>
                <p className={s.footer__rights}>2022 Ⓒ‎‎ All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
